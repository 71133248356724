import React from 'react';
// import sections
import TeamMembers from '../components/sections/TeamMembers';
import Cta from '../components/sections/Cta';

import classNames from 'classnames';


import coreValue1 from "../assets/images/coreValues-01.svg"
import coreValue2 from "../assets/images/coreValues-02.svg"
import coreValue3 from "../assets/images/coreValues-03.svg"
import coreValue4 from "../assets/images/coreValues-04.svg"
import coreValue5 from "../assets/images/coreValues-05.svg"
import CoreValueCard from '../components/sections/CoreValueCard';
import SectionHeader from '../components/sections/partials/SectionHeader';
import WhyUs from '../components/sections/WhyUs';

const sectionHeader = {
  paragraph: "GreenLight's core values evolved as the company grew - and so have we. As we continue to grow, we stand on them to drive the company and our lives."
};

const coreValues = [
  {
    title: "Value people",
    description: "Committed to each person linked to us, we appreciate and value them. We care about growing together and we’ll do whatever it takes to maintain that reciprocate connection and reach the top.",
    imageSrc: coreValue1,
    revealDelay: 100
  },
  {
    title: "Speak up",
    description: "Communication is the key in every team, and we want our people to be open and honest with each other. Sharing your opinion should never be a problem, knowing that you’ll be heard.",
    imageSrc: coreValue2,
    revealDelay: 300
  },
  {
    title: "Deliver Excellence",
    description: "Delivering excellence is not just providing quality work; it’s the continued effort our team makes to improve our services and communication on a daily basis. It’s to be kind, responsive and above all, dependable in every task assigned no matter how big or small the assignment is.",
    imageSrc: coreValue3,
    revealDelay: 500
  },
  {
    title: "Show kindness",
    description: "Of course that we value proactiveness and efficiency; but beyond that, we treasure kindness and teamwork. Being gentle and willing to help others creates the energy that we cherish.",
    imageSrc: coreValue4,
    revealDelay: 100
  },
  {
    title: "Seriously, relax",
    description: "Just because you have to work, doesn’t mean that you can’t take a minute to share a joke or watch a funny video. We love to laugh and we know that being happy helps to achieve goals better and faster.",
    imageSrc: coreValue5,
    revealDelay: 300
  },
]


const tilesClasses = classNames(
  'tiles-wrap',
  "mt-5",
  "reveal-from-left"
  //pushLeft && 'push-left'
);

const outerClasses = classNames(
  'testimonial section illustration-section-01',
);

const innerClasses = classNames(
  'testimonial-inner section-inner',
);

const AboutUs = () => {
  return (
    <section
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <div className="container-sm text-center">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              Values of the <span className="text-color-primary">people</span> behind the screens
            </h1>
          </div>
          <div className="container-xs text-center">
            <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
              {sectionHeader.paragraph}
            </p>
          </div>
          <div className={tilesClasses}>
            {
              coreValues.map(cv =>
                <CoreValueCard
                  title={cv.title}
                  description={cv.description}
                  imageSrc={cv.imageSrc}
                  reveal_delay={cv.revealDelay}
                  key={cv.title}
                />)
            }
          </div>
        </div>
      </div>

      <WhyUs />

      <TeamMembers />
      <Cta className="mt-md-5" split />
    </section>
  );
}

export default AboutUs;