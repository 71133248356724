import React, { useState } from 'react';
// import sections
import Form from '../components/sections/Form';
import Modal from '../components/elements/Modal';
import Button from "../components/elements/Button"

const Contact = () => {
  const [showModal, setShowModal] = useState(false)
  const [messageModal, setMessageModal] = useState("")
  const [titleModal, setTitleModal] = useState("")

  const showErrorModal = (title, message) => {
    setTitleModal(title)
    setMessageModal(message)
    setShowModal(!!title && !!message)
  } 

  return (
    <>
      <section className='section illustration-section-01'>
        <div className="container mt-5 reveal-from-bottom">
          <div className="row">
            <div className="col-sm">
              <h2 className='mt-0'>Let's talk about your needs!</h2>
              <p>
                We’d love to be your partner in this digital world, finding creative and useful solutions for you. Fill in the form to the right so we can reach you and have a conversation.
              </p>
            </div>
            <Modal handleClose={()=> showErrorModal("", "")} show={showModal} closeHidden={true}>
              <p>
                {titleModal}
              </p>
              <p>
                {messageModal}
              </p>
              <div className="modal-footer mb-0 pb-0">
                <Button tag="button" color="primary" onClick={() =>showErrorModal("","") }>Ok</Button>
              </div>
            </Modal>
            <div className="col-sm">
              <Form onSubmitted={()=> showErrorModal("Thanks!", "Your message was sent! We will contact you as soon as posible.")} onError={(data)=> showErrorModal("❌ Unexpected error", "Something was wrong. Please contact to the admnistrator")}/>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;