import React from 'react'

const Card2 = ({title, description, iconBootstrap, reveal_delay= 600}) => {

    return(
        <div className="tiles-item reveal-from-left text-center" style={{height: "100%"}} data-reveal-delay={reveal_delay}>
              <div className="tiles-item-inner">
                <div className="testimonial-item-footer text-xs my-2 pt-0 d-flex align-items-center justify-content-center" style={{height: "4em"}}>
                  <h4 className="d-inline testimonial-item-name align-middle">
                    {title}
                  </h4>
                
                </div>
                <hr className='mt-3 align-middle' style={{height:"3px", border:"none",background: '#95C72D'}}/>
                {iconBootstrap && <i style={{ fontSize: "6rem" }} class={`text-color-primary my-3 bi bi-${iconBootstrap}`}></i>}
                <div className="mx-3">
                  <p className="text-sm mb-0">
                    {description}
                  </p>
                </div>
              </div>
        </div>
    )
}

export default Card2