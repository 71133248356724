import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: <>Our <span className='text-color-primary'>talent</span> in</>,
    paragraph: 'We combine the latest technologies and best practices delivering outstanding products. Our team is capable to build and design the perfect solution for each client using the latest technology stacks and following the highest standards on the software industry.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                .Net | Data Science | Angular
                  </div>
                <h3 className="mt-0 mb-12">
                Boosting business
                  </h3>
                <p className="m-0">
                We have been involved in the analysis, designing and development of web and desktop systems that help small and big companies, with more than fifty years of experience, to manage from stock inventory to employees’ leaves and onboarding. We aim to make others improve and grow in their industry, facilitating their workflow and eliminating hassles.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-01.png')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                Ionic | Contentful | React | COSU
                  </div>
                <h3 className="mt-0 mb-12">
                Making life simpler
                  </h3>
                <p className="m-0">
                Sometimes people just need a fast and clean application to minimize the complications of life. We have developed systems and applications, both web and mobile, to alleviate routine activities like buying school supplies or get-well bouquets and even livestock, or taking a minute to relax and breathe. We’ve also helped users to save money by extending alarm system control to tablets, making use of kiosk mode.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-02.png')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                Android | Java | Spring Boot | Vue | React
                  </div>
                <h3 className="mt-0 mb-12">
                Charity & Influencers
                  </h3>
                <p className="m-0">
                Giving back to the community is a purpose that we share with our clients. We’ve built and designed web and mobile applications with an easy and intuitive user interface, to help people with the search of services and professionals, pet care and even money donation. We take into serious consideration the final goal of the products we build and the frameworks used. We’ve used partners’ framework, impulsing it into the market.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-03.png')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;