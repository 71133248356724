import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import ReactGA from 'react-ga';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import Home from './views/Home';
import Contact from './views/Contact';
import AboutUs from './views/AboutUs';
import Services from './views/Services';
import Terms from './views/Terms';
import Policy from './views/Policy';
import UpButton from './components/elements/UpButton';

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const trackPage = page => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const App = () => {

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add('is-loaded')
    childRef.current.init();
    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <ScrollReveal
        ref={childRef}
        children={() => (
          <Switch>
            <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
            <AppRoute exact path="/contact" component={Contact} layout={LayoutDefault} />
            <AppRoute exact path="/about" component={AboutUs} layout={LayoutDefault} />
            <AppRoute exact path="/services" component={Services} layout={LayoutDefault} />
             <AppRoute exact path="/terms" component={Terms} layout={LayoutDefault} />
          <AppRoute exact path="/privacy" component={Policy} layout={LayoutDefault} />
          </Switch>
        )} />
      <UpButton />
    </>
  );
}

export default App;