import React from 'react'

const CoreValueCard = ({title, description, imageSrc, reveal_delay= 600}) => {

    return(
        <div className="tiles-item reveal-from-left" data-reveal-delay={reveal_delay}>
              <div className="tiles-item-inner">
                <div className="testimonial-item-footer text-xs mb-0 pt-0">
                  <img className='d-inline' alt='core value card' src={imageSrc} width="80"/>
                  <h4 className="d-inline testimonial-item-name text-color-primary align-middle">{title}</h4>
                  <hr className='mt-1 mb-2 align-middle' style={{background: '#95C72D'}}/>
                </div>
                <div className="">
                  <p className="text-sm mb-0">{description}</p>
                </div>
              </div>
        </div>
    )
}

export default CoreValueCard