import React from 'react';
import Cta from '../components/sections/Cta';
// import sections
import FeaturesTiles from '../components/sections/FeaturesTiles';

const Services = () => {
  return (
    <section className='section illustration-section-01'>
      <FeaturesTiles className="reveal-from-bottom"/>
      <Cta className="mt-md-5" split/>
    </section>
  );
}

export default Services;