import React from 'react';

import classNames from 'classnames';
import Card2 from '../elements/Card2';

const points = [
    {
        title: "Time Zone",
        description: <>Our teams work in US time zones for  <span className='text-color-primary'><b>optimal collaboration</b></span>, offering a highly adaptive work model.</>,
        iconBootstrap: "clock-history",
        reveal_delay: 200
    },
    {
        title: "Tech Talent & English Proficiency",
        description: <>We provide highly experienced,  <span className='text-color-primary'><b>multilingual talent</b></span> who enhance your software development and quality assurance capacity.</>,
        iconBootstrap: "chat-left-text",
        reveal_delay: 400
    },
    {
        title: "Cost-effectiveness",
        description: <>Our clients can   <span className='text-color-primary'><b>save a significant amount of many</b></span> compared to traditional onshore contractors. We provide a smoothly internal recruiting and hiring processes to find the <span className='text-color-primary'><b>right project team members</b></span>.</>,
        iconBootstrap: "graph-down",
        reveal_delay: 600
    }
]

const WhyUs = () => {

    const tilesClasses = classNames(
        'tiles-wrap',
        "mt-5",
        "reveal-from-left",
    );

    return (
        <>
            <div className='container-fluid illustration-section-04'>
                <div className='container section-inner pt-3'>
                    <h2 className='text-center mt-0 pb-0 reveal-from-bottom' data-reveal-delay="200">Why <span className="text-color-primary">Us</span>?</h2>
                    <div className={tilesClasses}>
                        {points.map(p =>
                            <div className='mb-5'>
                                <Card2 title={p.title} description={p.description} imageSrc={p.imageSrc} iconBootstrap={p.iconBootstrap} reveal_delay={p.reveal_delay} />
                            </div>)}
                    </div>
                </div>
            </div>
        </>
    );
}

export default WhyUs;