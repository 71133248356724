import React from "react";

const ProfileMember = ({name, position, size, imageSrc, linkedinUrl}) => {
  const nameStyle= {
    color: "#ECEDED",
    fontWeight: "bold",
    marginTop: "16px"
  }
  const positionStyle= {
    color: "#ECEDED",
    fontSize: "16px"
  }

  const imgStyle={
    filter:"grayscale(50%)"
  }

  const socialStyle={
    fontSize: "24px",
  }

    return (
    <div>
        <img  style={imgStyle} src={imageSrc} alt="team-member" className="rounded-circle"  width={size} height={size}/> 
        <div style={nameStyle}>{name}</div>
        <div style={positionStyle}>{position}</div>
        <div className="primary-text" style={socialStyle}>
            { linkedinUrl &&  <a href={linkedinUrl} target="_blank" rel='noopener noreferrer'>  <i className="bi bi-linkedin"></i></a> }
        </div>
    </div>
  );
}

export default ProfileMember;