import React, { useEffect, useState } from "react";
import { api } from "../../api/email";
import "./form.css";
import Button from "../elements/Button"

const Form = ({onSubmitted, onError}) => {
    const initialValues = { fullname: "", email: "", message: "" };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [sendingEmail, setSendingEMail] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmit(true);
    };

    useEffect(() => {
        if(isSubmit && Object.keys(formErrors).length === 0){
            // declare the data fetching function
            const fetchData = async () => {
                setSendingEMail(true)
                let data = await api.sendEmail(formValues);
                if (data.status === 200) {
                    const json = await data.json();
                    if (json.success === true && json.data.accepted.length > 0) {
                        setIsSubmit(false)
                        setFormErrors({})
                        setFormValues(initialValues)
                        onSubmitted(true)
                        setSendingEMail(false)
                        return json;
                    } else {
                        onError(data)
                        setSendingEMail(false)
                        return false;
                    }
                } else {
                    onError(data)
                    setSendingEMail(false)
                    return false;
                }
            }
            fetchData()
                .catch(console.error);       
        }
    }, [formErrors, isSubmit])

    const validate = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!values.fullname) {
            errors.fullname = "Full Name is required!";
        }
        if (!values.email) {
            errors.email = "Email is required!";
        } else if (!regex.test(values.email)) {
            errors.email = "This is not a valid email format!";
        }
        if (!values.message) {
            errors.message = "Message is required";
        } else if (values.message.length < 4) {
            errors.message = "Message must be more than 4 characters";
        } else if (values.message.length >1000) {
            errors.message = "Message cannot exceed more than 10 characters";
        }
        return errors;
    };

    return (
        <section>
            {/* {Object.keys(formErrors).length === 0 && isSubmit ? (
                <div className="ui message success">Signed in successfully</div>
            ) : (
                <pre>{JSON.stringify(formValues, undefined, 2)}</pre>
            )} */}

            <form onSubmit={handleSubmit} className="mx-lg-5">
                <div className="form-group">
                    <label htmlFor="fullName">Full Name</label>
                    <input
                        className="form-control"
                        type="text"
                        name="fullname"
                        placeholder="Full Name"
                        value={formValues.fullname}
                        onChange={handleChange}
                    />
                </div>
                <p>{formErrors.fullname}</p>

                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        className="form-control"
                        type="text"
                        name="email"
                        placeholder="Email"
                        value={formValues.email}
                        onChange={handleChange}
                    />
                </div>
                <p>{formErrors.email}</p>

                <div className="form-group">
                    <label htmlFor="Message">Message</label>
                    <textarea
                        className="form-control"
                        type="tex"
                        name="message"
                        placeholder="Message"
                        value={formValues.message}
                        onChange={handleChange}
                        rows="5"
                    />
                </div>
                <p>{formErrors.message}</p>
                <div className="d-grid gap-2">
                    <Button tag="button" color="primary" loading={sendingEmail}>
                        Send
                    </Button>   
                    {/* <button className="button button-primary">Submit</button> */}

                </div>
     
      </form >
    </section >
  );
}

export default Form;