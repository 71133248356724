import React from "react";
import ProfileMember from "../elements/ProfileMember";
import imageMember1 from "../../assets/images/team-member-1.png"
import imageMember2 from "../../assets/images/team-member-2.png"
import imageMember3 from "../../assets/images/team-member-3.png"
import imageMember4 from "../../assets/images/team-member-4.png"
import imageMember5 from "../../assets/images/team-member-5.png"

const TeamMembers = () => {
    const ceoMembers = [
      {name: "Soledad Pavicich", position: "CEO & Partner", imageSrc:imageMember1, linkedinUrl:"https://www.linkedin.com/in/soledadpavicich/", size:350},
      {name: "Sebastian Pavicich", position: "CFO & Partner", imageSrc:imageMember3, linkedinUrl:"https://www.linkedin.com/in/sebasti%C3%A1n-pavicich-77371736/", size: 350},
    ]

    const otherMember = [
      {name: "Cecilia Pavicich", position: "HR & CM", imageSrc:imageMember2, size:250},
      {name: "Juan Martin Fernandez", position: "Full Stack Developer", imageSrc:imageMember5, size: 250},
      {name: "Agustina Ayala", position: "QA & UX/UI", imageSrc:imageMember4, size: 250},
    ]

    return(
    <section className="section center-content container section-inner pt-0">
        <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">  Management <span className="text-color-primary">Team</span></h1>
        <p className="reveal-from-bottom" data-reveal-delay="400">A committed team in every step.</p>

        <div className="row mt-5 reveal-from-bottom" data-reveal-delay="600">
          {
            ceoMembers.map(member => 
              <div className="col-md mt-4" key={member.name}>
                <ProfileMember name={member.name} position={member.position} size={member.size} imageSrc={member.imageSrc} linkedinUrl={member.linkedinUrl}/>
              </div>
            )
          }
        </div>

        <div className="row mt-md-4 reveal-from-bottom">
        {
            otherMember.map(member => 
              <div className="col-md mt-4" key={member.name}>
               <ProfileMember name={member.name} position={member.position} size={member.size} imageSrc={member.imageSrc}/>
            </div>
            )
          }
        </div>
    </section>
    )
 }

export default TeamMembers;
 
 