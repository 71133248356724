const sendEmail = async ({email, fullname, message}) => 
    await fetch(`${process.env.REACT_APP_BASE_API}/api/email`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            email,
            fullname,
            message
        }),
    })

export const api = {sendEmail}


