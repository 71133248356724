import React, { useEffect, useState } from "react";

const UpButton = () => {
    const listenToScroll = () => {
        let heightToHideFrom = 200;
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

        if (winScroll > heightToHideFrom) {
            !isVisibleUp && setIsVisibleUp(true);
        } else {
            setIsVisibleUp(false);
        }
    };

    const [isVisibleUp, setIsVisibleUp] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () =>
            window.removeEventListener("scroll", listenToScroll);
    }, [])

    return (
        isVisibleUp
        &&
        <button className='button position-fixed text-color-primary' color="primary"
            onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }}
            style={{
                fontSize: '48px',
                bottom: "40px",
                right: '0px',
                background: "transparent",
                textAlign: 'center',
            }}
        >
            <i class="bi bi-arrow-up-circle"></i>
        </button>

    )
}

export default UpButton