import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Logo from './partials/Logo';
import FooterNav from './partials/FooterNav';
import FooterSocial from './partials/FooterSocial';

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool
}

const defaultProps = {
  topOuterDivider: false,
  topDivider: false
}

const Footer = ({
  className,
  topOuterDivider,
  topDivider,
  ...props
}) => {

  const classes = classNames(
    'site-footer center-content-mobile',
    topOuterDivider && 'has-top-divider',
    className
  );

  return (
    <footer
      {...props}
      className={classes}
    >
      <div className="container">
        <div className={
          classNames(
            'site-footer-inner',
            topDivider && 'has-top-divider'
          )}>
          <div className="footer-top text-xxs">
            <div className='row'>
              <div className='col-sm footer-top text-xxs space-between'>
                <FooterSocial />
              </div>

              <div className='col-sm-auto text-center' style={{color: "#ECEDED"}}>
                <div className='row'>
                  <h6 className='mb-1'>
                  </h6>
                </div>
                <div className='row g-3 justify-content-center'>
                  <div className='col-auto'>
                    <div className='row g-0'>
                      <div className='col'>
                        <div className='row g-2'>
                          <div className='col-auto'>
                            <i className="bi bi-linkedin"></i>
                          </div>
                          <div className='col pr-5 mr-5 text-start'>
                            <a href='https://www.linkedin.com/in/soledadpavicich/' target="_blank" rel='noopener noreferrer'>Soledad Pavicich</a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='row g-2'>
                      <div className='col-auto'>
                        <i className="bi bi-envelope-fill"></i>
                      </div>
                      <div className='col-auto text-start'>
                        <a href='mailto:info@greenlightsoft.io'>info@greenlightsoft.io</a>
                      </div>
                    </div>

                    <div className='row g-2'>
                      <div className='col-auto'>
                        <i className="bi bi-telephone-fill"></i>
                      </div>
                      <div className='col-auto text-start'>
                        (302) 208-6425
                      </div>
                    </div>
                  </div>

                  <div className='col-auto'>
                  <div className='col-auto'>
                      <a href='https://goo.gl/maps/VyZpSKWNHUU2BsAh7' target="_blank" rel="noopener noreferrer">
                        <div className='row g-2'>
                          <div className='col-auto'>
                            <i className="bi bi-map-fill"></i>
                          </div>
                          <div className='col-auto text-start'>
                              8 The Green STE B
                            <span className='d-sm-block'> Dover, DE 19901</span>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            {/* <Logo /> */}
          </div>
          <div className="footer-bottom space-between text-xxs">
            {/* <FooterNav /> */}
            <div className="footer-copyright">
              <a style={{marginRight: "32px"}} href='privacy'>Privacy Policy</a>
              <a style={{marginLeft: "32px"}} className="pl-5 ml-5" href='/terms'>Terms & Conditions</a>
            </div>
            <div className="footer-copyright">Copyright ©️ 2022 GreenLight IT Solutions LLC</div>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;